import "./index.css"
import { Link } from "react-router-dom"
const BlackButton = (props) => {
  const { children } = props
  return (
    <button className="BlackButton">{children}</button>
  )
}
export default BlackButton

export const BlackButton2 = (props) => {
  const { children ,link} = props
  return (
    <a href={link}  target="_blank" className="BlackButton2">{children}</a>
  )
}

import RouteSetting from './Router';
import GalleryTemplate from './components/templates/GalleryTemplates';
import Item from './components/atoms/Item';

function App() {
  return (
    <>
    <RouteSetting />
    </>
  );
}

export default App;

// 要素を、ギャラリービュー形式で並べるテンプレート


import Footer, { BrandFooter2 } from "../../atoms/Footer"
import { BrandFooter } from "../../atoms/Footer"
import "./index.css"
import logo from "../../../assets/miisel_logo_top.svg"
import { Link } from "react-router-dom"
import { BlackButton2 } from "../../atoms/Button"



const PageTemplate = (props) => {
  const { children } = props
  return (
    <div className="pagetemplate-outer-container">
      <header>
        <Link to={"/"}><img src={logo} /></Link>
        <BlackButton2 link={"https://miisel.page.link/lp_head"}>アプリで見る</BlackButton2>

      </header>
      <div className="pagetemplate-inner-container">
        {children}
        <Footer />
      </div>
    </div>
  )

}
export default PageTemplate

export const LinkChangablePageTemplate = (props) => {
  const { children, link } = props
  return (
    <div className="pagetemplate-outer-container">
      <header>
        <Link to={"/"}><img src={logo} /></Link>
        <BlackButton2 link={link}>アプリで見る</BlackButton2>

      </header>
      <div className="pagetemplate-inner-container">
        {children}
        <Footer />
      </div>
    </div>
  )

}

export const BrandPageTemplate = (props) => {
  const { children } = props
  return (
    <div className="pagetemplate-outer-container">
      <header>
        <Link to={"/"}><img src={logo} /></Link>
        <BlackButton2 link={"https://miisel.page.link/lp_head"}>アプリで見る</BlackButton2>
      </header>
      <div className="pagetemplate-inner-container">
        {children}
        <BrandFooter />
      </div>
    </div>
  )

}

export const LPPageTemplate = (props) => {
  const { children } = props
  return (
    <div className="lppagetemplate-outer-container">
      <header>
        <Link to={"/"}><img src={logo} /></Link>
        <BlackButton2 link={"https://miisel.page.link/lp_head"}>アプリで見る</BlackButton2>

      </header>
      <div className="lppagetemplate-inner-container">
        {children}
        <BrandFooter2 />
      </div>
    </div>
  )
}
// フッター


import { Link } from "react-router-dom"
import { execCopy } from "../../../../hooks/UseCopy";
import Snackbar from '@mui/material/Snackbar';
import { useState } from "react";
import "./index.css"
import Share from "../../../../assets/share.svg"
const ItemComment = (props) =>{
    const{accountIcon,accountName,itemTitle,itemComment,user_url,itemId} = props
    // リンク共有時の処理
const shareData = {
    title: itemTitle,
    text: itemTitle,
    url: `https://miisel.com/i/${itemId }`
  };
  async function onShareLink() {
    try {
      await navigator.share(shareData);
    } catch (err) {}
  }

  // [スナックバーの処理]
const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  
  const { vertical, horizontal, open } = state;
  
  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  
  const onShare = ()=>{
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
      // スマホ・タブレット（iOS・Android）の場合の処理を記述
      onShareLink()
    }else{
      // PCの場合の処理を記述
    execCopy(`https://miisel.com/i/${itemId}`)

  
      handleClick({
        vertical: 'bottom',
        horizontal: 'center',
      })()
      setTimeout(() => {
        handleClose()
      }, 2000);
    }
   
  }
  
    return(
        <div className="itemComment-container" >
            <div className="itemcomment-header">
                <Link to={`/${user_url}`} style={{ textDecoration: 'none' ,width:"calc(100% - 24px)",display:"flex",alignItems:"center"}}>
                <img src={accountIcon} alt="" />
                <h2>{accountName}</h2>
                </Link>
                <button className="itemComment-sharebutton" onClick={onShare}>
                    <img src={Share} alt="" />
            </button>
            </div>
            <h1>
                {itemTitle}
            </h1>
            <p>
                {itemComment}
            </p>
            <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="    クリップボードにコピーしました"
        key={vertical + horizontal}
      />
        </div>
        
    )
}
export default ItemComment
// カテゴリ一覧のスクロールメニュー

import MenuItem from "../../atoms/MenuItem";
import ScrollMenu from "../../templates/ScrollMenu";
import "./index.css"

const CategoryScrollMenu = (props)=>{
  const {categoryList,selectedId,setSelectedId} = props;


  const categoryArray = categoryList.map((category,index)=>{
    if (category.id === selectedId){
      return <MenuItem key={index} isSelect={true}>{category.name}</MenuItem>
        }else{
        return<MenuItem key={index} isSelect={false} setSelectedId={setSelectedId} categoryId={category.id}>{category.name}</MenuItem>
        }
  }

  )
return(
  <>
  <ScrollMenu>{categoryArray}</ScrollMenu>
  </>
)
}
export default CategoryScrollMenu


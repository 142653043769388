// カルーセル


import "./index.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
const Carousel = (props) =>{
    const {images} = props;
    
    const imageArray = images.map(image=>{
        return (
          
        <div key={image} className="carousel-container">
         <img src={image} />
        </div>
        )
    })
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
        pauseOnHover:false
    }
    return(

        <Slider {...settings}>
         {imageArray}
        </Slider>
    )
}
export default Carousel
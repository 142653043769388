import "./index.css"
const TopBtn = (props) => {
  const { children, link } = props
  return (
    <div className="topbtn-container">
      <a href={link}>
        <button className="topbtn-btn">{children}</button>
      </a>
    </div>
  )
}
export default TopBtn


import amazon from "../assets/amazon.png"
import rakuten from "../assets/rakuten.png"
export const ShopMaster = {
    "amazon":{
        "logo":amazon,
        "name":"amazon"},
        "rakuten":{
            "logo":rakuten,
            "name":"楽天市場"
        }
}
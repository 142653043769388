// 商品一覧の商品１つ１つのパーツ


import { Link } from "react-router-dom";
import { RectPlaceHolder } from "../PlaceHolder";
import "./index.css"
const Item = (props) => {
    const { title, img, itemId, width } = props;
    return (
        <Link to={`/i/${itemId}`} style={{ textDecoration: 'none' }}>
            <div className="item-container" >
                <img src={img} />
 
                <p>{title}</p>

            </div>
        </Link>
    )
}
export default Item
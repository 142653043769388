// 横スクロールメニュー１つ１つのアイテム
import { ShopMaster } from "../../../masters/ShopMaster";
import "./index.css"
const ShoppingBtn = (props) =>{
    const {shop,price,link} = props;
    return(
       <div className="shoppingbtn-container">

           <a className="shoppingbtn-btn" href={link} target="_blank">
               <div className="shoppingbtn-icon">
               <img src={ShopMaster[shop].logo} alt="" />
               </div>
              
               <div className="shoppingbtn-label">
                   <h3>
                       {ShopMaster[shop].name}で購入
                   </h3>
                   <h2>
                       ￥ {price}
                   </h2>
               </div>
           </a>
       </div>
    )
}

// オリジナルの商品登録の場合のリンク
export  const ShoppingBtnOriginal = (props) =>{
    const {link} = props;
    return(
       <div className="shoppingbtn-container">

           <a className="shoppingbtn-btn" href={link} target="_blank">
              
               <div className="shoppingbtn-original-label">
                   <h2>
                       サイトにアクセスする
                   </h2>
               </div>
           </a>
       </div>
    )
}

export default ShoppingBtn
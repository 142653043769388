import axios from "axios"
import { useState } from "react"
import { useHistory } from "react-router"
import { URIHead } from "../setting"
import { brankCategoryItems } from "./BrankContents"
// API一式
export const UseAPI = () => {
    // [変数定義]
    // ユーザー情報格納
    const [userInfo, setUserInfo] = useState({})
    // カテゴリー・アイテム情報格納
    const [categoriesItems, setCategoriesItems] = useState([brankCategoryItems])
    // ローディング中かどうか
    const [loading, setLoading] = useState(true);

    // 商品の情報格納
    const [itemInfo, setItemInfo] = useState({
        "item_name": "",
        "description": "",
        "price": 0,
        "image_url": [],
        "affiliates_urls": [
            {
                "affiliate_url": "",
                "issued_from": "https://www.amazon.co.jp/"
            }
        ],
        "user": {
            "name": "",
            "icon_url": "",
        }
    })
    // 全商品を格納
    const [allItems, setAllItems] = useState([])
    // ページ遷移のためのhooksを定義
    const history = useHistory()

    // topページのリトライ
    var retry = 0;
    // [ユーザー情報取得API]
    const getUserInfo = (user_url) => {

        axios.get(`${URIHead}user/${user_url}`)
            .then(res => {
                if (res.data) {
                    setUserInfo(res.data);


                } else {
                    console.log("ユーザー情報返却なし")
                    history.push("/notfound")
                }
            })
            .catch(error => {

                if (!error.response) {
                    if (retry < 2) {
                        retry += 1;
                        getUserInfo(user_url)
                    } else {
                        history.push("/notfound")
                    }
                }

            }
            )
    }

    // [ユーザーのカテゴリー・アイテム一覧を取得する]
    const getCategoriesItems = (user_url) => {
        setLoading(true)
        axios.get(`${URIHead}user/${user_url}/categories-items`)
            .then(
                res => {
                    if (res.data) {
                        setCategoriesItems(res.data)

                        setLoading(false)


                    }
                }
            )
            .catch(error => {

                if (!error.response) {
                    if (retry < 3) {
                        getCategoriesItems(user_url)
                    } else {
                        history.push("/notfound")
                    }
                }

            })
    }

    // [その人の全商品を取得する]
    const getAllItems = (user_url) => {
        setLoading(true)

        axios.get(`${URIHead}user/${user_url}/items`)
            .then(
                res => {
                    if (res.data) {
                        setAllItems(res.data)
                    }
                }
            )
            .catch(error => {

                if (!error.response) {
                    if (retry < 3) {
                        getAllItems(user_url)
                    } else {
                        history.push("/notfound")
                    }
                }

            })
    }

    // 商品詳細ページのリトライ
    var retry2 = 0;

    // [商品の情報を取得する]
    const getItemInfo = (item_id) => {
        setLoading(true)
        if (isNaN(item_id)) {
            history.push("/notfound")
            return
        }

        axios.get(`${URIHead}item/${item_id}`)
            .then(res => {

                if (res.data && res.status === 200) {
                    console.log(res.data)
                    setItemInfo(res.data)
                    setLoading(false)
                } else {

                    history.push("/notfound")
                }
            })
            .catch(error => {

                if (!error.response) {
                    if (retry2 < 3) {
                        retry2 += 1;
                        getItemInfo(item_id)
                    } else {
                        history.push("/notfound")
                    }
                }
                history.push("/notfound")

            })
    }





    return { getUserInfo, userInfo, getCategoriesItems, categoriesItems, getItemInfo, itemInfo, getAllItems, allItems, loading }
}
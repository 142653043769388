// カテゴリコメント


import "./index.css"
const CategoryComment = (props) =>{
    const {children} = props
    return(
        <div className="categorycomment-container">
            {children}
        </div>        
    )
}
export default CategoryComment
// BrandPage
import "./index.css"

import { URL_INSTA } from "../../../setting"
import { URL_X } from "../../../setting"

import { LPPageTemplate } from "../../templates/PageTemplates"
import logo from "../../../assets/miisel_logo.svg"
import beta from "../../../assets/miisel_logo_top.svg"
import appstore from "../../../assets/appstore.svg"
import GformBtn from "../../atoms/GformBtn"
import topLogo from "../../../assets/brandpage_logo.svg"
import topImg from "../../../assets/brandpage_top_img.png"
import mainTopImg from "../../../assets/main-top.png"
import mainTopImgSP from "../../../assets/main-top-sp.png"

import feature1 from "../../../assets/feature1.png"
import feature2 from "../../../assets/feature2.png"
import feature3 from "../../../assets/feature3.png"

import featureimg1 from "../../../assets/featureimg1.png"
import featureimg2 from "../../../assets/featureimg2.png"
import featureimg3 from "../../../assets/featureimg3.png"
// import line from  "../../../assets/line.png"
import x_ from "../../../assets/x.png"
import insta from "../../../assets/insta.png"
import * as FadeIn from "./Fade";


export const BrandPage = () => {
  return (
    <LPPageTemplate>
      <div className="brandpage-top">
        <div className="brandpage-pc">

          <div className="brandpage-pc-message">
            <FadeIn.Up delay={100}>
              <img src={beta} alt="logo" />
            </FadeIn.Up>
            <FadeIn.Up delay={300}>
              <h2>あなたの好きが、価値になる。</h2>
            </FadeIn.Up>
            <FadeIn.Up delay={500}>
              <p>好きなものを簡単にシェアし合えるSNS。<br />シェアして貯めたポイントで、新しいものとの出会いも広がる。</p>
            </FadeIn.Up>
          </div>

          <FadeIn.Up delay={1000}>
            <img src={topImg} alt="top-img" width="400px" height="488px" />
          </FadeIn.Up>
        </div>
        <div className="brandpage-sp">
          <FadeIn.Up delay={100}>
            <img src={beta} alt="logo" className="brandpage-top-logo" />
          </FadeIn.Up>
          <FadeIn.Up delay={600}>
            <img src={topImg} className="brandpage-sp-img" alt="top-img" />
          </FadeIn.Up>
          <div className="brandpage-sp-message">
            <FadeIn.Up delay={1000}>
              <h2>あなたの好きが、<br />価値になる。</h2>
            </FadeIn.Up>
            <FadeIn.Up delay={1300}>
              <p>好きなものを簡単にシェアし合えるSNS。<br />シェアして貯めたポイントで、新しいものとの出会いも広がる。</p>
            </FadeIn.Up>
          </div>

        </div>
      </div>

      <div className="brandpage-reseption">
        <p className="brandpage-pc-reseption-message">みんなの好きなものを、アプリでチェック！</p>
        <p className="brandpage-sp-reseption-message">みんなの好きなものを<br />アプリでチェック！</p>
        <a href="https://miisel.page.link/lp_main" target="_blank">
          <img src={appstore} alt="appstore" />
        </a>
      </div>

      <div className="brandpage-main">
        <img src={mainTopImg} className="brandpage-main-topimg" alt="whats miisel" width="584" height="85px" />
        <img src={mainTopImgSP} className="brandpage-main-topimg-sp" alt="whats miisel" width="200px" height="130px" />
        <div className="brandpage-main-container">
          <FadeIn.Up >
            <div className="brandpage-main-part">
              <div className="brandpage-main-caption">

                <img className="brandpage-feature" src={feature1} alt="" />
                <h2>アノ人の<br />お気に入りをチェック</h2>
                <p className="brandpage-pc-only">友人や、あなたの憧れの人がどんなモノを気に入り、<br />マイページにならべるのか。<br />今まで知らなかったアノ人の新たな一面が見られます。</p>
                <p className="brandpage-sp-only">友人や、あなたの憧れの人がどんなモノを気に入り、マイページにならべるのか。<br />今まで知らなかったアノ人の新たな一面が見られます。</p>
              </div>
              <img src={featureimg1} className="brandpage-featureimg" alt="" />
            </div>
          </FadeIn.Up>
          <FadeIn.Up >
            <div className="brandpage-main-part brandpage-pc-only">
              <img src={featureimg2} className="brandpage-featureimg" alt="" />
              <div className="brandpage-main-caption">
                <img className="brandpage-feature" src={feature2} alt="" />
                <h2>自分の愛用品を<br />ひとまとめに</h2>
                <p className="brandpage-pc-only">「どこの使ってるんですか？」と聞かれた時に<br />せっかくならちゃんと紹介したい。<br /><br />
                  自分のお気に入りをひとまとめにしておけば、<br />いつでもシェアできます。</p>

              </div>

            </div>
          </FadeIn.Up>
          <FadeIn.Up >
            <div className="brandpage-main-part brandpage-sp-only">

              <div className="brandpage-main-caption">
                <img className="brandpage-feature" src={feature2} alt="" />
                <h2>自分の愛用品を<br />ひとまとめに</h2>
                <p>「どこの使ってるんですか？」と聞かれた時にせっかくならちゃんと紹介したい。<br /><br />
                  自分のお気に入りをひとまとめにしておけば、いつでもシェアできます。</p>
              </div>
              <img src={featureimg2} className="brandpage-featureimg" alt="" />

            </div>
          </FadeIn.Up>
          <div className="brandpage-main-part">
            <div className="brandpage-main-caption">
              <img className="brandpage-feature" src={feature3} alt="" />
              <h2>気に入ったアイテムは<br />そのまま購入</h2>
              <p>好みのアイテムを見つけたらその場ですぐに購入できます。<br />わざわざ探さなくても、スキマ時間にときめきと出会えます。</p>
            </div>
            <img src={featureimg3} className="brandpage-featureimg" alt="" />
          </div>

        </div>
      </div>
      <div className="brandpage-footer">
        <img src={beta} alt="logo" class="brandpage-footer-logo" />
        <div className="brandpage-sns">
          <a href={URL_INSTA} target="_blank">
            <img src={insta} alt="" />
          </a>
          <a href={URL_X} target="_blank">
            <img src={x_} alt="" />
          </a>
        </div>
      </div>






    </LPPageTemplate >
  )
}

// TermPage
import PageTemplate from "../../templates/PageTemplates"
import "./index.css"

export const TermPage = () => {
  return (
    <PageTemplate>
      <div className="termpage-container">
        <h2>利用規約</h2>
        <div>
          この利用規約（以下，「本規約」といいます。）は，愛用品シェアサービス「miisel」とその関連サービス（以下，「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用・閲覧する皆さまには，本規約に従って，本サービスをご利用いただきます。
        </div>
        <h3>第1条 適用</h3>
        <ul>
          <li>
            本規約は、本サービスの利用に関する、本サービスとユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、登録者と本サービスの間の本サービスの利用に関わる一切の関係に適用されます。
          </li>
        </ul>
        <h3>第2条 定義</h3>
        <ul>
          <li>
            本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
            <ul>
              <li>「ユーザー」とは、第3条に基づき本サービスの利用者として登録やユーザーページを閲覧した個人又は法人を意味します。</li>
              <li>「本サービスウェブサイト」とは、そのドメインが「miisel.com」である本サービスが運営するウェブサイト(理由の如何を問わず本サービスのウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。)を意味します。</li>
              <li>「本サービス」とは、本サービスが提供するmiiselという名称のアプリケーション、Webサービス(理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。)を意味します。</li>
            </ul>
          </li>
        </ul>

        <h3>第3条 登録</h3>
        <ul>
          <li>本サービスの利用を希望する者は、本規約を遵守することに同意し、かつ本サービスの定める一定の情報(以下「登録情報」といいます。)を本サービスの定める方法で本サービスに提供することにより、本サービスに対し、本サービスの利用の登録を申請することができます。未成年である場合は、登録する前に親権者の包括的な同意を得ている必要があります。</li>
          <li>ユーザーページの閲覧のみの場合は機能が制限されます。</li>
          <li>
            本サービスは、利用登録の申請者に以下の事由があると判断した場合、利用登録を拒否することがあり、その理由については一切の開示義務を負わないものとします。
            <ol>
              <li>本規約に違反するおそれがあると本サービスが判断した場合</li>
              <li>本サービスに提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合 </li>
              <li>過去に本サービスの利用の登録を取り消された者である場合</li>
              <li>その他、本サービスが登録を適当でないと合理的に判断した場合</li>
            </ol>
          </li>
        </ul>

        <h3>第4条 アカウント情報の管理</h3>
        <ul>
          <li>ユーザーは、自己の責任において、本サービスにかかるユーザーID及びパスワード（以下「アカウント情報」といいます。）を管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。</li>
          <li>アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、本サービスは一切の責任を負いません。</li>
          <li>ユーザーは、アカウント情報が盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を本サービスに通知するとともに、本サービスからの指示に従うものとします。</li>
        </ul>

        <h3>第5条 利用規約の変更</h3>
        <ul>
          <li>当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスを利用した場合には、当該ユーザーは変更後の規約に同意したものとみなします。</li>
        </ul>

        <h3>第6条 本サービスの停止等</h3>
        <ul>
          <li>
            本サービスは、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
            <ul>
              <li>本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合</li>
              <li>コンピューター、通信回線等が事故により停止した場合</li>
              <li>火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
              <li>外部サービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合</li>
              <li>その他、本サービスが停止又は中断を合理的に必要と判断した場合</li>
            </ul>
          </li>
          <li>本サービスは、本サービスの合理的な判断により、本サービスの提供を終了することができます。この場合、本サービスは登録ユーザーに事前に通知するものとします。</li>
          <li>本サービスは、本条に基づき本サービスが行った措置に基づきユーザーに生じた損害について一切の責任を負いません。</li>
        </ul>

        <h3>第7条 禁止行為</h3>
        <ul>
          <li>
            ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
            <ul>
              <li>本サービス、又は他の登録ユーザー、外部事業者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
                犯罪行為に関連する行為又は公序良俗に反する行為</li>
              <li>法令又は本サービス若しくはセレクターが所属する業界団体の内部規則に違反する行為</li>
              <li>コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為</li>
              <li>本サービスが定める一定のデータ容量以上のデータを本サービスを通じて送信する行為</li>
              <li>本サービスによる本サービスの運営を妨害するおそれのあると合理的に認められる行為</li>
              <li>その他、本サービスが不適切と合理的に判断する行為</li>
            </ul>
          </li>
          <li>
            登録者は、以下の各号に該当する情報を、本サービスを通じて公開してはならないものとし、以下の各号に該当し、又は該当するおそれのある活動情報が公開された場合は、本サービスは、当該活動情報の公開停止その他の措置を実施することができるものとします。 本項に定める措置によりユーザーに損害が生じた場合でも、本サービスは、当該損害について一切の責任を負わないものとします。
            <ul>
              <li>他のユーザーその他の第三者を誹謗中傷する内容を含む情報</li>
              <li>他のユーザーその他の第三者の知的財産権を侵害する内容を含む情報</li>
              <li>第三者に成りすますことを企図した情報その他の当該登録ユーザーを他人と誤認させる情報</li>
              <li>第三者の氏名、住所、電話番号その他の個人情報に該当する情報</li>
              <li>詐欺その他の違法行為に関連するウェブサイトのURLを掲載する行為</li>
              <li>本規約に違反する情報</li>
              <li>前各号に定める他、本サービスが不適切であると合理的に判断する情報</li>
            </ul>
          </li>
        </ul>

        <h3>第8条 退会</h3>
        <ul>
          <li>ユーザーは本サービスの定める退会手続により、本サービスから退会できるものとします。</li>
        </ul>

        <h3>第9条 権利帰属</h3>
        <ul>
          <li>当社ウェブサイト及び本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本規約において明示されているものを除き、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。
            ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為をしないものとします。</li>
          <li>当社ウェブサイト又は本サービスにおいて、ユーザーが投稿その他送信を行った文章、画像、動画その他のデータについては、当社において、本サービス及び関連サービスの円滑な提供のみならず、新規コンテンツ作成、出版、販売、その他の商業的利用を含むあらゆる目的で、無償かつ非独占的に使用、改変、翻訳、二次的著作物の作成（新たな記事の作成を含む）、公開、配布することができるものとし、ユーザーはこれを許諾するものとします。この権利許諾は、当社がユーザーの投稿内容を基にした新規コンテンツを作成し、任意の形式で利用、公開することを可能にします。ユーザーは、当社に対し、これらの活動に必要な全ての権利、権限及び利益を無償で許諾することに同意します。</li>
        </ul>

        <h3>第10条 個人情報の取扱い</h3>
        <ul>
          <li>本サービスにユーザーの個人情報の取扱いについては、別途定める本サービスのプライバシーポリシーに従い適切に取り扱うものとします。</li>
          <li>本サービスは、ユーザーが本サービスに提供した情報、データ等を、個人を特定できない形での統計的な情報として、本サービスの裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を述べないものとします。</li>
        </ul>

        <h3>第11条 アフィリエイト</h3>
        <ul>
          <li>本サービスは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。</li>
          <li>
            当社は本サービス内でユーザーとアフィリエイト提供会社の間でトラブルがあっても一切の関与をしないものとします</li>
        </ul>
        <h3>第12条 ユーザ資格の取り消し</h3>
        <ul>
          <li>以下の事由が存在する又はその可能性があると当社が判断した場合には、当社は、当該ユーザーによる本サービスの利用の一時中断、又は本ユーザー資格の取消を行うことがあります。
            <ul>
              <li>本規約のいずれかの条項に違反した場合</li>
              <li>ユーザー名及びログインパスワードを不正に使用した場合</li>
              <li>本サービスで提供する情報を不正に使用した場合</li>
              <li>他のユーザーの名前を使用し、あたかも本人であるかのように振る舞っていた場合</li>
              <li>登録事項に虚偽の事実があることが判明した場合</li>
              <li>破産、民事再生、会社更生または特別清算の手続開始決定等の申立がなされたとき</li>
              <li>1年間以上本サービスの利用がない場合</li>
              <li>当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合</li>
              <li>第3条第3項各号に該当する場合</li>
              <li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
              <li>その他本規約の記載又は趣旨もしくは本サービスの趣旨に反する行為がある場合</li>
            </ul>
          </li>

          <li>ユーザーは、本ユーザー資格を取消された場合、本アカウントを使用して利用できるサービスについても、利用資格が取消しされます。</li>
        </ul>
        <h3>第13条 著作権</h3>
        <ul>
          <li>ユーザーは、自ら著作権等の必要な知的財産権を有するか、または必要な権利者の許諾を得た文章、画像や映像等の情報のみ、本サービスを利用し、投稿または編集することができるものとします。</li>
          <li>ユーザーは、自己以外の第三者（サービス提供者およびサービス提供者に前項の画像等の利用を許諾した者を除く）が著作権を有する著作物を本サービス内に掲載する場合、事前に当該第三者から当該著作物を当社およびユーザーが使用することについて許諾を得なければなりません。</li>
        </ul>
        <h3>第14条 保証の否認および免責事項</h3>
        <ul>
          <li>当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</li>
          <li>当社は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。</li>
          <li>当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。</li>
        </ul>
        <h3>第15条 サービス内容の変更等</h3>
        <ul>
          <li>当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。</li>
        </ul>
        <h3>第16条 通知または連絡</h3>
        <ul>
          <li>ユーザーと当社本サービスとの間の通知または連絡は、本サービスの定める方法によって行うものとします。</li>
        </ul>
        <h3>第17条 権利義務の譲渡の禁止</h3>
        <ul>
          <li>ユーザーは、本サービスの書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。</li>
        </ul>
        <h3>第18条 準拠法・裁判管轄</h3>
        <ul>
          <li>本規約の解釈及び適用は、日本法に準拠して解釈するものとします。</li>
          <li>本規約により解決できない問題が生じた場合には、ユーザーと本サービスは、双方誠意をもって話し合い、これを解決するものとします。</li>
          <li>本規約から生じる又は関連するいかなる訴訟又は訴訟手続きについても東京地方裁判所を第1審の専属管轄裁判所とします。</li>
        </ul>
        <h3>第19条 収益化機能</h3>
        <ul>
          <li>本サービスは、ユーザーが投稿した商品へのアクセス数や該当投稿のリンク経由での商品売上等に基づき、本サービスから分配される収益を得ることができる機能（以下、「収益化機能」といいます。）を提供します。</li>
          <li>収益化機能に参加しているユーザーは、収益化機能の利用にあたり、以下の行為をしてはなりません。これらの違反行為が確認された場合、該当ユーザーのポイント交換申請を中止し、利用規約第１２条に基づき、ユーザー資格の剥奪等の措置を取ることがあります。
            <ul>
              <li>スパムを利用した集客行為。</li>
              <li>意図的に誤クリックを誘発する行為。</li>
              <li>miiselのページであることを隠して集客する行為。</li>
              <li>何らかの応募要項としてプロフィールにアクセスさせる行為</li>
              <li>プロフィールのURLを短縮URLに変換して集客する行為（Xなどの外部サービスによる自動短縮を除く）</li>
            </ul>
          </li>
          <li>以下の場合は収益の支払いを行わない、または既に加算された収益を修正することがあります。
            <ul>
              <li>投稿が正常な手段によるものでない場合（例：スパム投稿、不正行為、システムを悪用した行為）</li>
              <li>当社サービスの不具合やシステム障害により不正確なポイントが加算された場合</li>
              <li>その他、不測の事態や当社が不適切と判断した場合</li>
            </ul>
          </li>
          <li>ユーザーは収益化機能により獲得したポイントを、所定の手続きを通じて電子ギフトカードやその他のデジタル報酬形式等の形態へ交換申請することができます。当社は、ポイント交換申請を受け付けた後、システムの確認を行い、不正行為やシステム欠陥による誤ったポイント加算がないかを検証します。検証の結果、ポイント加算が正確であると判断された場合のみ、対象ユーザーにデジタルインセンティブを付与します。</li>
          
        </ul>
        
      </div>

    </PageTemplate>
  )
}
// フッター

import "./index.css"
import logo from "../../../assets/miisel_logo.svg"

const Footer = () => {
    return (
        <div className="footer-container" >
        <h2>powered by miisel</h2>
        <div className="footer-menu">
            <a href="/s/term">利用規約</a>
            <a href="/s/privacypolicy">プライバシーポリシー</a>
        </div>
        </div>

    )
}
export default Footer

export const BrandFooter = () => {
    return (
        <div className="footer-container-brand" >
            <img className="footer-menu-logo" src={logo} />
            <div className="footer-menu-brand">
                <a href="/s/term">利用規約</a>
                <a href="/s/privacypolicy">プライバシーポリシー</a>
            </div>
        </div>

    )}
// ロゴなしのフッター
    export const BrandFooter2 = () => {
        return (
            <div className="footer-container-brand" >
                <div className="footer-menu-brand">
                    <a href="/s/term">利用規約</a>
                    <a href="/s/privacypolicy">プライバシーポリシー</a>
                </div>
            </div>
    
        )}
// アカウントのトップページ（商品一覧ページ）

import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { UseAPI } from "../../../hooks/UseAPI"
import { TestCategoryList, TestItemList, TestItems } from "../../../TestData"
import Item from "../../atoms/Item"
import AccountInfo from "../../molecures/AccountInfo"
import CategoryScrollMenu from "../../molecures/CategoryScrollMenu"
import GalleryTemplate from "../../templates/GalleryTemplates"
import PageTemplate, { LinkChangablePageTemplate } from "../../templates/PageTemplates"
import "./index.css"
import { brankItem } from "../../../hooks/BrankContents"


const TopPage = () => {
  // [変数定義・読み込み]
  // 選択中のカテゴリID
  const [selectedId, setSelectedId] = useState("all")
  // カテゴリ一覧
  const [categoryList, setCategoryList] = useState([])
  // 選択中のカテゴリのアイテム一覧
  const [itemList, setItemList] = useState([brankItem])
  // URLからuser_urlを取得する
  const { user_url ,category_id} = useParams();
  // APIから情報を取得するためのカスタムフックスを読み込む
  const { getUserInfo, userInfo, getCategoriesItems, categoriesItems,getAllItems,allItems,loading} = UseAPI();
  // カテゴリ一覧から検索された場合
  const [categoryLoading,setCategoryLoading] = useState(true)

  // カテゴリが読み込み済みの場合、カテゴリをセットする
  const setCategory = function(){
    console.log(categoriesItems.filter(category => { return category.category_id === Number(category_id) }).length)
    if(
      categoriesItems.filter(category => { return category.category_id === Number(category_id) }).length >0
    ){
      setSelectedId(Number(category_id))
      setCategoryLoading(false)
    }else{
      setSelectedId("all")
      setCategoryLoading(false)
    }
  }

 

  // [ページが読み込まれた時に行う処理]
  useEffect(() => {
    // APIでユーザー情報を取得する
    getUserInfo(user_url)
    // APIでカテゴリー・アイテム情報を取得する
    getCategoriesItems(user_url)
    // APIで全商品を取得する
    getAllItems(user_url)
    // カテゴリidがURLにセットされていなかったら、categoryLoadingをfalseに
    if(!category_id){setCategoryLoading(false)}

  }, [])

   // [ページが読み込まれた時に行う処理]
   useEffect(() => {
       // カテゴリー・アイテムから、カテゴリーリストを作成
       setCategoryList(createCategoryList(categoriesItems))
      //  URLのカテゴリーをセットする
       setCategory()
  }, [categoriesItems])

  // [スクロールメニューで選択しているカテゴリが変更された際の処理]
  useEffect(() => {

    var itemList = null
    
    if(selectedId == "all"){
      // allを選択中は、全商品をitemListに格納
      if(allItems.count !== 0){
        itemList = allItems.map(item=>{
          return <Item title={item.item_name} img={item.image_url[0]} itemId={item.item_id} />
        })
      }
    }else {
      // all以外のカテゴリを選択時は、選択されているカテゴリIDの商品をitemListに格納
     

    const items = categoriesItems.filter(category => { return category.category_id === selectedId })[0].items
    console.log(items)
    itemList = items.map(item => {
      return <Item title={item.item_name} img={item.image_url[0]} itemId={item.item_id} />
    })
    
      
  }
    setItemList(itemList)
    
  }, [selectedId,allItems])

  return (
    <LinkChangablePageTemplate link={`https://miisel.page.link/?link=http://miisel.com/u/${user_url}&isi=1617520752&ibi=com.miisel&ofl=https://itunes.apple.com/jp/app/id1617520752?mt=8`}>
      <AccountInfo bgImage={userInfo.background_url} accountName={userInfo.name} accountId={user_url} accountDetail={userInfo.profile} accountIcon={userInfo.icon_url} externalUrl={userInfo.external_url} wantlistUrl={userInfo.want_list_url}/>
      {loading? <></> :
      <CategoryScrollMenu categoryList={categoryList} selectedId={selectedId} setSelectedId={setSelectedId} />
     
    }
      <div className="toppage-maincontaints">
       {
          !categoryLoading ?
       <GalleryTemplate items={itemList} comments={selectedId !=="all" & categoryList.filter(category => { return category.id === selectedId }).count >0 ? categoryList.filter(category => { return category.id === selectedId })[0].comment : null} loading={loading}/>:null
         
       }
      </div>
    </LinkChangablePageTemplate>
  )
}

// [関数]
// Categories-itemsを、カテゴリーリストに変換する関数
const createCategoryList = (categories_items) => {
  // allを入れる
  const categoryList_1 = [{ id: "all", name: "すべて" }]
  // Categories-itemsから、カテゴリーに関係するものを取り出して、categoryList_2へ格納
  const categoryList_2 = categories_items.map(
    categoryItems => {
      return { id: categoryItems.category_id,name: categoryItems.category_name,comment:categoryItems.description}
    }
  )
  // categoryList_1とcategoryList_2を連結
  const categoryList = categoryList_1.concat(categoryList_2)
  return categoryList
}



export default TopPage


// 要素を、ギャラリービュー形式で並べるテンプレート


import "./index.css"

import Masonry from "react-masonry-component";
import CategoryComment from "../../atoms/Comment/CategoryComment";
import { RectPlaceHolder } from "../../atoms/PlaceHolder";

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: ".my-bg-image-el" };

const GalleryTemplate = (props) =>{
    const {items,comments,loading} = props
    const itemArray = 
    loading? [1,2,3,4,5,6].map((item,index) =>{
      return <div key={index} style={{width:"48%",margin: "1% 1%"}}><RectPlaceHolder w={"100%"} h={100+200*Math.random()} x={0} y={0} r={10}/></div>
    }):
    items.map((item,index) =>{
        return <div key={index} style={{width:"48%",margin: "1% 1%"}}>{item}</div>
    })
   
    
    return(
      <>
      {comments ?
        <CategoryComment>{comments}</CategoryComment>:null
      }
        <Masonry
        className={"my-gallery-class"} // default ''
        options={masonryOptions} // default {}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        imagesLoadedOptions={imagesLoadedOptions} // default {}
      >
          {itemArray}
         
      </Masonry>
      </>
    )
}
export default GalleryTemplate
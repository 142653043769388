// 

import "./index.css"
const ScrollMenu = (props)=>{
  const {children} = props
    return(
<div className="scroll-menu">
{children}
</div>
    )
}
export default ScrollMenu
import React from "react"
import ContentLoader from "react-content-loader"

export const RectPlaceHolder = (props)=>{
    const {x,y,w,h,r} = props
    return(
        <ContentLoader 
    speed={2}
    width={w=="100%"?"100%":x+w}
    height={h+y}
    // viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x={x} y={y} rx={r} ry={r} width={w} height={h} /> 
  </ContentLoader>
    )
}
    export const CirclePlaceHolder = (props)=>{
        const {x,y,w,h,r} = props
        return(
            <ContentLoader 
        speed={2}
        width={w=="100%"?"100%":x+w}
        height={h+y}
        // viewBox="0 0 400 160"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
    
    <circle cx={x} cy={y} r={r} />
      </ContentLoader>
        )

}
// アイテムページ（商品詳細ページ）


import { useEffect } from "react"
import { useParams } from "react-router"
import { UseAPI } from "../../../hooks/UseAPI"
import Carousel from "../../atoms/Carousel"
import ItemComment from "../../atoms/Comment/ItemComment"
import { RectPlaceHolder } from "../../atoms/PlaceHolder"
import ShoppingBtn from "../../atoms/ShoppingBtn"
import {ShoppingBtnOriginal} from "../../atoms/ShoppingBtn"
import PageTemplate, { LinkChangablePageTemplate } from "../../templates/PageTemplates"

import "./index.css"

const ItemPage = () => {
  // [変数定義・読み込み]
  // URLからitem_idを取得する
  const { item_id } = useParams();
 // APIから情報を取得するためのカスタムフックスを読み込む
 const { getItemInfo,itemInfo} = UseAPI();

  
  // [ページが読み込まれたときに行う処理]
  useEffect(()=>{
    getItemInfo(item_id)
  
  },[])
  return (
    <LinkChangablePageTemplate link={`https://miisel.page.link/?link=http://miisel.com/i/${item_id}&isi=1617520752&ibi=com.miisel&ofl=https://itunes.apple.com/jp/app/id1617520752?mt=8`}>
      <div className="itempage-container">
      {itemInfo.item_name==""?
      <>
          <RectPlaceHolder w={"100%"} h={200} r={10} x={0} y={0}/>
          <br/>
          <br/>
          </>
          :
        <div className="itempage-image">
          
          <Carousel images={itemInfo.image_url} />
  
        </div>
}
{itemInfo.item_name==""?
      <> <RectPlaceHolder w={"100%"} h={200} r={10} x={0} y={0}/>
      <br/>
      <br/>
      </>:
        <div className="itempage-comment">
          <ItemComment accountIcon={itemInfo.user.icon_url}
            accountName={itemInfo.user.name} itemTitle={itemInfo.item_name} itemComment={itemInfo.description} user_url={itemInfo.user.user_url} itemId={item_id }
          />
        </div>
}
      </div>
      
{      itemInfo.affiliates_urls[0].issued_from == "https://www.amazon.co.jp/" ?
      <ShoppingBtn shop={"amazon"} price={itemInfo.price.toLocaleString()} link={itemInfo.affiliates_urls[0].affiliate_url}/>
:        <ShoppingBtnOriginal  link={itemInfo.affiliates_urls[0].affiliate_url} />
      
    }
    </LinkChangablePageTemplate>
  )
}


export default ItemPage
// アカウント紹介パーツ

import { execCopy } from "../../../hooks/UseCopy";
import "./index.css"
import Snackbar from '@mui/material/Snackbar';
import { useState } from "react";
import { CirclePlaceHolder, RectPlaceHolder } from "../../atoms/PlaceHolder";
import Share from "../../../assets/share.svg"
import linkImg from "../../../assets/link.png"
import giftImg from "../../../assets/gift.png"


const AccountInfo = (props)=>{
const {bgImage,accountIcon,accountName,accountId,accountDetail,externalUrl,wantlistUrl} = props

// [リンク共有時の処理]
const shareData = {
  title: `${accountName}のmiisel`,
  text: `${accountName}のmiisel`,
  url: `https://miisel.com/${accountId}`
};
async function onShareLink() {
  try {
    await navigator.share(shareData);
   
  } catch (err) {}
}

// [スナックバーの処理]
const [state, setState] = useState({
  open: false,
  vertical: 'top',
  horizontal: 'center',
});

const { vertical, horizontal, open } = state;

const handleClick = (newState) => () => {
  setState({ open: true, ...newState });
};

const handleClose = () => {
  setState({ ...state, open: false });
};

const onShare = ()=>{
  if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
    // スマホ・タブレット（iOS・Android）の場合の処理を記述
    onShareLink()
  }else{
    // PCの場合の処理を記述
  execCopy(`https://miisel.com/${accountId}`)

    handleClick({
      vertical: 'bottom',
      horizontal: 'center',
    })()
    setTimeout(() => {
      handleClose()
    }, 2000);
  }
 
}

return(
  <>
  {bgImage ?
  <img className="accountinfo-bgImage" src={bgImage}/>:
  <RectPlaceHolder w={"100%"} h={100} r={5} x={0} y={0}/>
}
{
  accountIcon?
<img className="accountinfo-accountIcon" src={accountIcon} />:
<div className="accountinfo-accountIcon-placeholder">
<CirclePlaceHolder w={80} h={80} r={40} x={40} y={40}/>
</div>
}
  
  <div className="accountinfo-account-container">
    {accountName ?
    
  <h2 className="accountinfo-accountName">{accountName}</h2>:
  <RectPlaceHolder x={20} y={10} w={150} h={10} r={5}/>
    }
  <button className="accountinfo-sharebutton" onClick={onShare} >
  <img src={Share} className="accountinfo-share" />
  </button>
  </div>
  <p className="accountinfo-accountId">@{accountId}</p>
  <div className="accountinfo-accountDetail-container">
  {
    accountDetail?
<p className="accountinfo-accountDetail">{accountDetail}</p>:
  <p></p>
}{
  externalUrl == "" ?<p></p>:<div className="accountinfo-linkContainer"><img className="accountinfo-linkImg" src={linkImg}></img><a href={externalUrl} target="_blank" className="accountinfo-url" >{externalUrl}</a></div>
}
{
  wantlistUrl == "" ?<p></p>:<div className="accountinfo-linkContainer"><img className="accountinfo-linkImg" src={giftImg}></img><a href={wantlistUrl} target="_blank" className="accountinfo-url" >{wantlistUrl}</a></div>
}
</div>
  <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="クリップボードにコピーしました"
        key={vertical + horizontal}
      />
  </>
)
}


export default AccountInfo
export const brankItem = {
    "description": "",
    "image_url": [''],
    "item_id": 0,
    "item_name": "",
    "liked_num": 0
}

export const brankCategoryItems = {
    "category_id": 0,
"category_name": "",
"description": "",
"items": [brankItem]
}
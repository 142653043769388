import { Link } from "react-router-dom"
import "./index.css"
import { BrandPageTemplate } from "../../templates/PageTemplates"
import TopBtn from "../../atoms/TopBtn"

// NotFoundPage
export const NotFoundPage = () => {
  return (
    <BrandPageTemplate>
      <div className="notfoundpage-area">
        <h1 className="notfoundpage-title">404</h1>
        <h2 className="notfoundpage-subtitle">ページが見つかりませんでした</h2>
        <p className="notfoundpage-container">
          あなたがアクセスしようとしたページは<br />
          削除されたかURLが変更されているため<br />
          見つけることができません。
        </p>
        <TopBtn children={"トップページに戻る"} link={"/"} />
      </div>
    </BrandPageTemplate >
  )
}



import { useEffect, useRef } from "react";
import { Switch,BrowserRouter, Route} from "react-router-dom";
import { BrandPage } from "./components/pages/BrandPage";
import ItemPage from "./components/pages/ItemPage";
import { NotFoundPage } from "./components/pages/NotFoundPage";
import { PrivacyPolicyPage } from "./components/pages/PrivacyPolicyPage";
import { TermPage } from "./components/pages/TermPage";
import TopPage from "./components/pages/TopPage"
import ScrollToTop from "./ScrollToTop";

const RouteSetting = ()=>{
    const router = useRef(null);

  useEffect(() => {
    router.current.history.listen((location) => {
      window.gtag('config', 'G-CMYS86YBSD', {
        'page_path': location.pathname
      });
    });
  });
    return(
        <BrowserRouter ref={router}>
        <ScrollToTop />
        <Switch>
            <Route exact path="/" component={BrandPage} />
            <Route exact path="/notfound" component={NotFoundPage} />
            <Route exact path="/s/term" component={TermPage} />
            <Route exact path="/s/privacypolicy" component={PrivacyPolicyPage} />
            <Route exact path="/i/:item_id" component={ItemPage} />
            <Route exact path="/:user_url" component={TopPage} />
            <Route exact path="/:user_url/:category_id" component={TopPage} />
            <Route exact component={NotFoundPage} />
            </Switch>

        </BrowserRouter>
    )
}
export default RouteSetting;